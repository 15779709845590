










import {Component, Vue} from 'vue-property-decorator';
import PortalInput from "@/components/common/PortalInput.vue";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import SimpleProfileSearch from "@/components/common/SimpleProfileSearch.vue";
import ProfileDTO from "@/dto/profile/ProfileDTO";
import axios from "axios";
import {SIGNING_ENDPOINT} from "@/constants/endpoints";
import authHeader from "@/services/auth-header";
import Notifications from "@/state/Notifications";
import {processError} from "@/utils/ComponentUtils";
import {WorkspaceType} from "@/dto/auth/Workspace";

class Payload {
  templateName?: string;
  file?: FileMetaDTO;
  signers: Signer[] = [];
}

class Signer {
  order?: number;
  signerType?: WorkspaceType;
  id?: number;
}

@Component({
  components: {SimpleProfileSearch, SingleFileHolder, PortalInput}
})
export default class HardcodedSignableDocumentCreator extends Vue {

  payload = new Payload();

  currentOrder = 1;

  onSignerSelected(profile: ProfileDTO) {
    const signer = new Signer();
    signer.order = this.currentOrder++;
    signer.signerType = profile.type;
    signer.id = profile.id;
    this.payload.signers.push(signer);
  }

  create() {
    axios.post(`${SIGNING_ENDPOINT}/prefilled/hardcoded`, this.payload, {headers: authHeader()}).then(
        ok => Notifications.info("ok"),
        err => processError(err, this)
    )
  }

}
